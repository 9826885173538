import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from '../SectionTitle/SectionTitle';
import Vator from '../../images/testimonial/vector.png'
import Img1 from '../../images/testimonial/icone.png'
import Img2 from '../../images/testimonial/icone-2.png'
import Img3 from '../../images/testimonial/icone-3.png'


const testimonials = [
    {
        id: '01',
        img: Img1,
        Des: " “Je me sens beaucoup mieux dans ma peau depuis que je suis suivie par D-FIT. J'ai appris à manger équilibré et à gérer mon stress grâce aux séances de relaxation. Je recommande vivement à tous ceux qui souhaitent améliorer leur qualité de vie",
        title: 'Modern Sater',
        sub: "CEO of Digital Studio",
    },

    {
        id: '02',
        img: Img2,
        Des: " “J'ai suivi les conseils nutritionnels du diététicien et j'ai rapidement constaté une amélioration de ma silhouette. Les séances de sport encadrées m'ont permis de me dépasser et d'acquérir de nouvelles habitudes alimentaires",
        title: 'Marry Jenefer',
        sub: "CEO Of Golden Bravo",
    },
    {
        id: '03',
        img: Img3,
        Des: " “Grâce au programme personnalisé du centre, j'ai enfin réussi à perdre les kilos qui me complexaient depuis des années. L'équipe est à l'écoute et très professionnelle. Je me sens en pleine forme et plus confiante que jamais !",
        title: 'William Robert',
        sub: "CEO Of Bexima",
    },



]




const Testimonial = (props) => {

    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        arrows: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    return (

        <div className="orico-testimonial-area section-padding">
            <div className="t-shape"><img src={Vator} alt="" /></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <SectionTitle subtitle={'TÉMOIGNAGE DES CLIENTS'} title={'clients satisfaits'} />
                    </div>
                </div>
                <div className="orico-testimonial-wrap">
                    <div className="testimonial-slider">
                        <Slider {...settings}>
                        {testimonials.map((testimonial, item) => (
                                <div className="orico-testimonial-item" key={item}>
                                    <div className="orico-testimonial-img">
                                        <img src={testimonial.img} alt="" />
                                    </div>
                                    <div className="orico-testimonial-content">
                                        <p>{testimonial.Des}</p>
                                        <h2>{testimonial.title}</h2>
                                        <span>{testimonial.sub}</span>
                                    </div>
                                </div>
                        ))}
                        </Slider>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;







