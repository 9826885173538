import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import { toast } from 'react-toastify';



const ContactForm = () => {

    const [forms, setForms] = useState({
        nom: '',
        email: '',
        objet: '',
        telephone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const [submitStatus, setSubmitStatus] = useState(0);
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler  = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            setSubmitStatus(1);
            try {
                console.log(forms)
                await axios.post('https://www.api.dfit.fr/api/contact', forms,
                {
                    headers: {'Content-Type' : 'application/json', 'Accept' : 'application/json'},
                });
                setSubmitStatus(2);
                setForms({
                    nom: '',
                    email: '',
                    objet: '',
                    telephone: '',
                    message: ''
                });
                toast.success('Message envoyé !');
                // .then((res) => console.log(res.data))
                // .catch((err) => console.log(err.response.data.message));
                
                /*if(res.status === 200){
                    console.log('Formulaire envoyé !');
                    setForms({
                        name: '',
                        email: '',
                        subject: '',
                        phone: '',
                        message: ''
                    });
                    setSubmitStatus(2);
                } else {
                    console.error('Erreur lors de l\'envoi', res.statusText);
                    setSubmitStatus(3);
                    console.log(res)
                }*/
            } catch (error) {
                console.error('Type d\'erreur :', error.message);
                setSubmitStatus(3)   
            }
            
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.nom}
                            type="text"
                            name="nom"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Name" />
                        {validator.message('nom', forms.nom, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.telephone}
                            type="phone"
                            name="telephone"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your phone with country code" />
                        {validator.message('telephone', forms.telephone, 'required')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.objet}
                            type="text"
                            name="objet">
                            <option>Object</option>
                            <option>Edition plats diététiques</option>
                            <option>Cours cuisine diététique</option>
                            <option>Elaboration programmes alimentaires</option>
                        </select>
                        {validator.message('objet', forms.objet, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Message">
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">{submitStatus === 1 ? 'En cours...' : submitStatus === 2 ? 'Envoyé' : submitStatus === 3 ? 'Erreur' : 'Prendre contact'}</button>
            </div>
        </form >
    )
}

export default ContactForm;