
/* Single image */
import img1 from '../images/service/serv-plan.jpg'
import img2 from '../images/service/serv-cour.jpg'
import img3 from '../images/service/serv-livrer.webp'
import img4 from '../images/service/serv-prog.jpg'



import sion1 from '../images/service/icon-1.svg'
import sion2 from '../images/service/icon-2.svg'
import sion3 from '../images/service/icon-3.svg'
import sion4 from '../images/service/icon-4.svg'


import Simg1 from '../images/service-single/img1.jpg'
import Simg2 from '../images/service-single/img2.jpg'
import Simg3 from '../images/service-single/img3.jpg'
import Simg4 from '../images/service-single/img3.jpg'

const Services = [
    {
        id: 1,
        icon: sion1,
        simage: img1,
        imag: Simg1,
        title: 'Edition plans diététiques personnels',
        description: 'Des régimes sur mesure pour vos objectifs. Nous créons un plan nutritionnel personnalisé adapté à vos besoins et vos goûts',
        slug: 'Agriculture-Products'
    },
    {
        id: 3,
        icon: sion3,
        simage: img3,
        imag: Simg3,
        title: 'Livraison de repas diététique',
        description: 'Des repas équilibrés livrés où que vous soyez. Gagnez du temps et simplifiez votre quotidien tout en prenant soin de votre santé',
        slug: 'Organic-Fruits'
    },
    {
        id: 4,
        icon: sion4,
        simage: img4,
        imag: Simg4,
        title: 'Elaboration de programmes alimentaires',
        description: 'Besoin d\'un accompagnement personnalisé ? Nous élaborons des programmes alimentaires adaptés à vos objectifs et votre mode de vie',
        slug: 'Fresh-Vegetables'
    },
    {
        id: 2,
        icon: sion2,
        simage: img2,
        imag: Simg2,
        title: 'Cours de cuisine diététique',
        description: 'Apprenez à cuisiner sain et équilibré. Des cours pratiques pour maîtriser les bases de la cuisine diététique et réaliser des plats savoureux',
        slug: 'Dairy-Products'
    }
   


];
export default Services;