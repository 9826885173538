
/* Single image */
import img1 from '../images/service/fat-lost.jpg'
import img2 from '../images/service/ventre-plat.jpg'
import img3 from '../images/service/detox.webp'
import img4 from '../images/service/img-4.jpg'



import sion1 from '../images/service/icon-1.svg'
import sion2 from '../images/service/icon-2.svg'
import sion3 from '../images/service/icon-3.svg'
import sion4 from '../images/service/icon-4.svg'


import Simg1 from '../images/service-single/img1.jpg'
import Simg2 from '../images/service-single/img2.jpg'
import Simg3 from '../images/service-single/img3.jpg'
import Simg4 from '../images/service-single/img3.jpg'

const Progs = [
    {
        id: 1,
        icon: sion1,
        simage: img1,
        imag: Simg1,
        title: 'FAT LOST',
        description: 'Programme complet + gains musculaire, il est  à la fois un régime et une amélioration du mode de vie pour maintenir les kilos perdus. Il s\'étend sur 2 mois',
        slug: 'Agriculture-Products'
    },
    {
        id: 3,
        icon: sion3,
        simage: img3,
        imag: Simg3,
        title: 'DETOX',
        description: 'Il s’étant sur 2 semaines et est très intense. Conçut pour nettoyer les toxines accumulées dans le corps grâce à une alimentation riche en fibre et potassium',
        slug: 'Organic-Fruits'
    },
    {
        id: 2,
        icon: sion2,
        simage: img2,
        imag: Simg2,
        title: '21 JOURS VENTRE PLAT',
        description: 'Il s\'agit d\'un programme conçut pour perdre le ventre et diminuer le pourcentage de graisse corporelle',
        slug: 'Dairy-Products'
    }
   


];
export default Progs;