import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TopShape from '../../images/ft-bg.png'
import logo from '../../images/logo-dfit.png'
import FtShape1 from '../../images/ft-shape-1.png'
import FtShape2 from '../../images/ft-shape-2.png'
import Btnicon from '../../images/pointer.svg'



const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const Footer = (props) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateEmail(email)) {
            setError('');
            console.log('Email is valid:', email);
        } else {
            setError('Please enter a valid email address.');
        }
    };

    const { FooterShape = true } = props

    return (
        <div className={'' + props.hclass}>
            {FooterShape && (
                <div className="footer-shape">
                    <img src={TopShape} alt="" />
                </div>
            )}
            <footer className="wpo-site-footer" >
                <div className="wpo-upper-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget about-widget">
                                    <div className="logo widget-title">
                                        <img src={logo} alt="blog" />
                                    </div>
                                    <p>Atteignez vos objectifs de bien-être avec nos programmes personnalisés. Nutrition équilibrée, coaching sportif sur mesure et état d'esprit positif : rejoignez nous et adoptez un mode de vie sain et épanouissant.</p>
                                    <div className="social-widget">
                                        <ul>
                                            <li><a href='https://www.facebook.com/dfiit/?locale=fr_FR' target='blank'><i className="ti-facebook" aria-hidden="true"></i></a></li>
                                            <li><a href='https://twitter.com/d_fit_delivery' target='blank'><i className="ti-twitter" aria-hidden="true"></i></a></li>
                                            <li><a href='https://www.linkedin.com/company/d-fit-delivery/' target='blank'><i className="ti-linkedin" aria-hidden="true"></i></a></li>
                                            <li><a href='https://www.instagram.com/d_fit_delivery/' target='blank'><i className="ti-instagram" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget link-widget">
                                    <div className="widget-title">
                                        <h3>Liens rapides</h3>
                                    </div>
                                    <ul>
                                        <li><Link onClick={ClickHandler} to="/about">À propos</Link></li>
                                        <li><Link onClick={ClickHandler} to="/services">Services</Link></li>
                                        {/* <li><Link onClick={ClickHandler} to="/blog">Newsroom </Link></li> */}
                                        {/* <li><Link onClick={ClickHandler} to="/checkout">Checkout</Link></li> */}
                                        <li><Link onClick={ClickHandler} to="/shop">Produits</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget wpo-contact-widget">
                                    <div className="widget-title">
                                        <h3>Contact</h3>
                                    </div>
                                    <div className="contact-ft">
                                        <ul>
                                            <li><i className="fi flaticon-placeholder"></i>Bonamoussadi & Akwa, Douala-Cameroun </li>
                                            <li><i className="fi flaticon-phone-call"></i>+237 6 94 08 26 75</li>
                                            <li><i className="fi flaticon-email"></i>contact@dfit.fr</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget newsletter-widget">
                                    <div className="widget-title">
                                        <h3>Newsletter</h3>
                                    </div>
                                    <p>Vous serez notifié lorsque quelque chose de nouveau apparaitra.</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-1">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Adresse Mail *"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                        <div className="submit clearfix">
                                            <button type="submit">
                                                <i>
                                                    <img src={Btnicon} alt="Submit" />
                                                </i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wpo-lower-footer">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12  col-12">
                                <p className="copyright"> &copy;<span> D-FIT 2024</span>
                                    <span className="copyright-icon">|</span> Tous droits réservés.
                                </p>
                            </div>
                            <div className="col-lg-6 col-md-12  col-12">
                                <p className="link"> Conçu par <span><a style={{color:'#f29100'}} href="https://www.comtheplug.com" target='blank'>THEPLUG COM'</a></span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ft-shape-1"><img src={FtShape1} alt="" /></div>
                <div className="ft-shape-2"><img src={FtShape2} alt="" /></div>
            </footer>
        </div>
    )
}

export default Footer;







